import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Curso from "../components/curso";
import Tree from "../images/cursos/Tree.webp";
import Albert from "../Assets/albert_camus.webp";

const Camus = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted ? (
    <Layout>
      <Curso
        title="Albert Camus"
        phrase="Disfrutar la libertad del absurdo."
        time="10 Horas"
        class="8 Sesiones"
        students="3 - 6 Estudiantes"
        firstImg={Tree}
        secondImg={Albert}
        description="En este curso analizaremos las obras e ideas principales de A. Camus: pensador, escritor y dramaturgo que tuvo el valor de hablarnos sobre el sinsentido de la vida y la experiencia de existir en un mundo que supera nuestra comprensión. A través de la comprensión de sus ideas podemos desarrollar herramientas personales que nos permitan vivir con mayor libertad y ligereza."
        list={[
          "La sensación del absurdo: ideas principales del ensayo El mito de Sísifo.",
          "¿Vale la pena vivir la vida?",
          "Análisis del libro El Extranjero.",
          "Libertad, pasión y rebeldía.",
          "Análisis del libro La Caída.",
          "La respuesta de Camus ante el absurdo de la existencia.",
        ]}
        duracion="6 sesiones de entre 60 y 75 min."
        cupo="mínimo 3 personas, máximo 6 personas."
        precio=" $2,000 MXN / $120 USD aprox."
        top="10%"
        imgWidth="18%"
        right="7%"
        topMob="15%"
        rightMob="5%"
        imgWidthMob="28%"
      />
    </Layout>
  ) : null;
};

export default Camus;
